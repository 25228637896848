import { HttpClient } from '../common/httpClient';
import { MetaConversationId, MetaMessage, MetaMessageStatus, MetaMessageType } from '../../types/meta/metaMessage';
import { Page } from '../../types/page';
import { PageResponse } from '../common/pagingModel';
import dateTimeService from '../../service/dateTimeService';

type MetaMessageResponse = {
  readonly id: string;
  readonly type: string;
  readonly direction: 'outgoing' | 'incoming';
  readonly status: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly metaMessageId: string;
  readonly statusDetails: string;
  readonly message: {
    readonly text: string;
    readonly mediaUrl?: string;
    readonly filename?: string;
    readonly caption?: string;
  };
};

type PageMetaMessageResponse = PageResponse<MetaMessageResponse>;

type MetaUnreadConversationResponse = {
  readonly targetId: string;
  readonly accountId: string;
};

type MetaUnreadMessagesResponse = {
  readonly targetId: string;
  readonly accountId: string;
  readonly messages: string[];
};

type GetPageByTargetParams = {
  readonly accountId: string;
  readonly targetId: string;
  readonly page: number;
  readonly size: number;
  readonly sort: 'createdAt';
  readonly direction: 'asc' | 'desc';
};

export class MetaMessageApi {
  constructor(private readonly httpClient: HttpClient) {}

  getPageByTarget(params: GetPageByTargetParams): Promise<Page<MetaMessage>> {
    const { accountId, targetId, page, size, sort, direction } = params;

    const queryParams = [
      `accountUuid=${accountId}`,
      `page=${page}`,
      `size=${size}`,
      `sort=${sort},${direction}`,
    ].join('&');

    return this.httpClient.get<PageMetaMessageResponse>(`/accounts/${accountId}/targets/${targetId}/messages?${queryParams}`)
      .then(data => {
        const { number, size, totalElements, totalPages } = data;
        const content = data.content.map(it => this.mapToMetaMessage(it));

        return {
          content,
          pageNumber: number,
          pageSize: size,
          totalElements,
          totalPages,
        };
      });
  }

  getUnreadConversationsIds(tenantId: string): Promise<MetaConversationId[]> {
    return this.httpClient.get<MetaUnreadConversationResponse[]>(`/tenants/${tenantId}/conversations/unread`)
      .then(data => data.map(it => ({
        accountId: it.accountId,
        targetId: it.targetId,
      })));
  }

  getUnreadMessagesIds(accountId: string, targetId: string): Promise<string[]> {
    return this.httpClient.get<MetaUnreadMessagesResponse>(`/accounts/${accountId}/targets/${targetId}/count/unread`)
      .then(data => data.messages);
  }

  private mapToMetaMessage(response: MetaMessageResponse): MetaMessage {
    return {
      id: response.id,
      createdAt: dateTimeService.convertIsoDateToTimestamp(response.createdAt),
      updatedAt: dateTimeService.convertIsoDateToTimestamp(response.updatedAt),
      direction: response.direction,
      type: response.type as MetaMessageType,
      status: response.status as MetaMessageStatus,
      text: response.message.text,
      mediaURL: response.message.mediaUrl,
      fileName: response.message.filename,
      caption: response.message.caption,
      statusDetails: response.statusDetails,
    };
  }
}
