import axios from 'axios';
import { push } from 'react-router-redux';
import delay from 'lodash-es/delay';
import { appConfig } from '../config';
import { compareAsc, parseISO } from 'date-fns';
import {
  fetchBillingCycleTypeList,
  fetchCustomerTypeList,
  fetchFeeTypesList,
  fetchServiceList,
  fetchSmsStatusList,
  fetchStatusList,
  fetchUnauthorizedStatusList,
  fetchUserTagsList,
  getLogo,
  getMenu,
} from './app';
import { setAgentStatus } from './chat';
import { getAppearance } from './appearance';
import { AppThunkAction } from './index';
import { AnyAction } from 'redux';
import { getPendingForApprovalViberTemplatesForAdmin } from './templates/viber';
import viberApi from '../api/viber/viberApi';
import { ViberAccountConfig } from '../types/viber/viberAccount';
import metaApi from '../api/meta/metaApi';
import { WhatsappAccountConfig } from '../types/meta/metaAccount';
import { showSnackBarMsg } from './snackbar';
import { FormattedMessage } from 'react-intl';

export function doLogout(redirect: boolean = true): AppThunkAction {
  return (dispatch, getState) => {
    const agent = getState().login.userData.chatAgent;
    if (agent) dispatch(setAgentStatus('offline'));
    localStorage.removeItem('token');
    localStorage.removeItem('chatToken');
    localStorage.removeItem('redux');
    localStorage.removeItem('logo');
    localStorage.removeItem('columns-sms-cdrs');
    localStorage.removeItem('columns-viber-cdrs');
    localStorage.removeItem('columns-whatsapp-cdrs');
    localStorage.removeItem('columns-invoices');
    localStorage.removeItem('columns-balanceOverview');
    localStorage.removeItem('columns-accessLog');
    localStorage.removeItem('columns-finance');
    localStorage.removeItem('columns-campaignContacts');
    localStorage.removeItem('columns-campaignLog');
    localStorage.removeItem('columns-contacts');
    localStorage.removeItem('columns-uploadingContacts');
    dispatch({ type: 'IS_NOT_AUTHENTICATED', payload: 'unauthenticated' });
    if (redirect) dispatch(push('/login'));
  };
}

export function sessionExpired(): AppThunkAction {
  return (dispatch) => {
    dispatch(doLogout());
    dispatch(showSnackBarMsg(<FormattedMessage id='login.session.expired.info' />, 'info'));
  };
}

export function isAuthenticated(): AppThunkAction<Promise<void>> {
  return (dispatch) =>
    new Promise((resolve) => {
      const url = `${appConfig.URL_REST}user`;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      };
      axios
        .get(url, config)
        .then((response: $TSFixMe) => {
          if (!response.data.active) {
            dispatch(doLogout());
          }
          dispatch({ type: 'IS_AUTHENTICATED', payload: response.data });
          if (response.data.authorizations['SUPER_ADMIN']) {
            dispatch(fetchFeeTypesList());
            dispatch(getPendingForApprovalViberTemplatesForAdmin());
          }
          resolve(response);
        })
        .catch((err) => {
          console.error(err);
          dispatch(doLogout());
        });
    });
}

export function openPage(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'REFRESH_LOGIN_PAGE', payload: '' });
  };
}

export function doLogin(username: string, password: string, code?: string, redirectUrl?: string): AppThunkAction {
  if (username && password) {
    return (dispatch, getState) => {
      dispatch({ type: 'LOGIN_START', isLoading: true });

      axios
        .post(`${appConfig.URL}api/authentication`, { email: username, password: password, ...(code && { code }) })
        .then((response) => {
          if (response.data['2fa'] === 'SMS') {
            dispatch(setSmsVerificationDialogShow(true));
            dispatch({ type: 'LOGIN_STOP', isLoading: false });
          } else {
            const { token, chatToken } = response.data;
            localStorage.removeItem('token');
            localStorage.setItem('token', token);
            localStorage.setItem('chatToken', chatToken);

            dispatch({
              type: 'ADD_SNACKBAR',
              variant: 'success',
              msg: 'Login successfull',
            });
            dispatch(isAuthenticated()).then(() => {
              dispatch(setSmsVerificationDialogShow(false));
              dispatch(fetchStatusList());
              dispatch(fetchServiceList());
              dispatch(fetchSmsStatusList());
              dispatch(fetchUnauthorizedStatusList());
              dispatch(fetchCustomerTypeList());
              dispatch(fetchBillingCycleTypeList());
              dispatch(fetchUserTagsList());
              dispatch(push(redirectUrl ? redirectUrl : '/dashboard'));
              dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
              dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
              dispatch(getLogo());
              dispatch(getAppearance());
              dispatch(getMenu());
              dispatch(showWelcomeScreen());
              dispatch(getAccountInfo());
              dispatch(getUserViberConfig());
              dispatch(getUserWhatsappConfig());
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response === undefined) {
            dispatch({
              type: 'LOGIN_FAILED',
              payload: { message: 'loginFailed' },
            });
          } else {
            if (code) {
              dispatch({
                type: 'SMS_VERIFICATION_FAILED',
                payload: { message: 'smsVerificationFailed' },
              });
              dispatch({
                type: 'LOGIN_STOP',
                isLoading: false
              });
            } else
              dispatch({
                type: 'LOGIN_FAILED',
                payload: { message: 'authenticationFailed' },
              });
            delay(() => dispatch({ type: 'CLEAN_FAILED' }), 5000);
          }
        });
    };
  }
  return (dispatch) => {
    dispatch({ type: 'LOGIN_EMPTY', payload: { message: 'EmptyPWD' } });
    delay(() => dispatch({ type: 'CLEAN_FAILED' }), 5000);
  };
}

export const showWelcomeScreen = (): AppThunkAction => {
  return (dispatch, getState) => {
    const hideTestAccountWelcomeScreenNextTime = localStorage.getItem(
      'hideTestAccountWelcomeScreenNextTime'
    );
    const { userData } = getState().login;
    const url =
      userData._links &&
      userData._links.account_info &&
      userData._links.account_info.href;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    if (url) {
      axios
        .get(url, config)
        .then((res) => {
          if (
            res.data.customerType === 'DEMO' &&
            !hideTestAccountWelcomeScreenNextTime
          ) {
            dispatch({
              type: 'SET_SHOW_TEST_ACCOUNT_WELCOME_SCREEN',
              payload: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const getAccountInfo = (): AppThunkAction => {
  return (dispatch, getState) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const { userData } = getState().login;
    const url =
      userData._links &&
      userData._links.account_info &&
      userData._links.account_info.href;
    if (url) {
      axios
        .get(url, config)
        .then((res) => {
          if (res.data.customerType === 'DEMO') {
            dispatch({ type: 'SET_ACCOUNT_INFO', payload: res.data });
            if (compareAsc(parseISO(res.data.validTo), new Date()) === -1) {
              delay(() => {
                dispatch({
                  type: 'SET_SHOW_TEST_ACCOUNT_EXPIRED_SCREEN',
                  payload: true,
                });
              }, 200);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export function setSmsVerificationDialogShow(flag: boolean): AnyAction {
  return { type: 'SET_SMS_VERIFICATION_CODE_DIALOG_SHOW', payload: flag };
}

export function getUserViberConfig(): AppThunkAction {
  return (dispatch, getState) => {
    const tenantId = getState().login.userData.customerUuid!;
    viberApi.config.getUserConfig(tenantId)
      .then((userConfig: ViberAccountConfig) => {
        userConfig &&
          dispatch({
            type: 'SET_USER_VIBER_CONFIG',
            payload: userConfig.featureFlags,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getUserWhatsappConfig(): AppThunkAction {
  return (dispatch, getState) => {
    const tenantId = getState().login.userData.customerUuid!;
    metaApi.whatsappConfig.getUserConfig(tenantId)
      .then((userConfig: WhatsappAccountConfig) => {
        userConfig &&
          dispatch({
            type: 'SET_USER_WHATSAPP_CONFIG',
            payload: userConfig.featureFlags,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
